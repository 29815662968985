// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-coursemaker-gatsby-theme-coursemaker-pages-success-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/pages/success.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-pages-success-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-lecture-page-template-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/lecture-page-template.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-lecture-page-template-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-list-courses-page-template-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/list-courses-page-template.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-list-courses-page-template-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-privacy-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/privacy.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-privacy-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-school-login-page-template-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/school-login-page-template.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-school-login-page-template-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-school-registration-page-template-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/school-registration-page-template.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-school-registration-page-template-js" */),
  "component---src-coursemaker-gatsby-theme-coursemaker-templates-terms-js": () => import("./../../../src/@coursemaker/gatsby-theme-coursemaker/templates/terms.js" /* webpackChunkName: "component---src-coursemaker-gatsby-theme-coursemaker-templates-terms-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-404-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/pages/404.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-404-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-cancel-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/pages/cancel.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-cancel-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-forgot-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/pages/forgot.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-pages-forgot-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-curriculum-page-template-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/templates/course-curriculum-page-template.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-curriculum-page-template-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-landing-page-template-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/templates/course-landing-page-template.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-landing-page-template-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-payment-page-template-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/templates/course-payment-page-template.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-course-payment-page-template-js" */),
  "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-school-landing-page-template-js": () => import("./../../../../../../student-app-builder/node_modules/@coursemaker/gatsby-theme-coursemaker/src/templates/school-landing-page-template.js" /* webpackChunkName: "component---student-app-builder-node-modules-coursemaker-gatsby-theme-coursemaker-src-templates-school-landing-page-template-js" */)
}

